/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.AvailableCouponPopup {
    .Popup{
        &-Content{
            border-radius: 0;
            padding: 30px;
        }
        &-Heading{
            font-size: 18px;
            font-weight: 700;
            padding-block-end: 24px;
            border-bottom: solid 1px $grey-4;
            margin: 0;
            text-transform: inherit;
            @include mobile {
                text-align: left;
            }
        }
    }
    &-Content{
        > div{
            border-bottom: solid 1px $grey-4;
            padding: 20px 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            font-size: 14px;
            font-weight: 700;
            .coupon-code{
                text-transform: uppercase;
                width: 20%;
                @include mobile {
                    width: 50%;
                    text-align: right;
                }
            }
            .coupon-title{
                width: 50%;
                p{
                    margin-top: 7px;
                }
            }
        }
    }
}