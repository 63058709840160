@font-face {
    font-family: 'BoltonRegular';
    src: url(/style/fonts/Bolton-Regular.woff2) format('woff2'),
    url(/style/fonts/Bolton-Regular.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'BoltonBold';
    src: url(/style/fonts/Bolton-Bold.woff2) format('woff2'),
    url(/style/fonts/Bolton-Bold.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

$font-boltonregular: 'BoltonRegular', sans-serif;
$font-boltonbold: 'BoltonBold', sans-serif;


body {
    font-family: $font-boltonregular;
    border: solid 8px $default-primary-base-color;
    min-height: 100%;
}

html{
    height: 100%;
}