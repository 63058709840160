/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 .NotificationList {
    position: fixed;
    z-index: 99999;
    inset-block-start: calc(var(--header-height) + var(--breadcrumbs-height) + 20px);
    inset-inline-end: 30px;
    max-width: 550px;
    list-style: none;

    @include desktop {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
    }

    @include mobile {
        overflow: hidden;
        max-width: 100vw;
        position: sticky;
        width: 100%;
        inset-block-start: 90px;
        inset-inline-end: 0;
    }
}
