/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ToggleButton{
  background: $grey-5;
  width: 65px;
  height: 34px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-left: auto;
  margin-bottom: 15px;
  border-radius: 500px;
  &-Button{
    font-weight: 700;
    font-size: 12px;
    color: $default-primary-base-color;
    width: 28px;
    height: 28px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &_isActive{
      background: $default-primary-base-color;
      color: $white;
      border-radius: 500%;
    }
  }
}
.ToggleButtonWrapper-content{
  width: 80%;
  margin: 0 auto;
  @include mobile{
    width: 90%;
  }
  table{
    width: 100%;
    margin: 0;
    color: $default-primary-base-color;
    thead{
      th{
        text-align: center;
        padding: 20px 0;
        font-size: 14px;
        border: solid 1px $grey-4;
      }
    }
    tbody{
      td{
        text-align: center;
        padding: 15px 0;
        font-size: 14px;
        border: solid 1px $grey-4;
      }
    }
  }
}
