/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 .BlogRelatedPosts {
    &-TitleWrapper {
        border-bottom: 1px solid var(--color-black);
        height: fit-content;
        margin-bottom: 21px;
    }

    &-Title {
        width: fit-content;
        font-size: 2rem;
        border-bottom: 3px solid var(--color-black);
        margin-bottom: 0;
    }

    &-Wrapper {
        margin: 0 10px;
    }

    &-PostsWrapper {
        display: grid;
        grid-gap: 40px 20px;
        grid-template-rows: repeat(auto-fill, 1fr);
        grid-template-columns: repeat(2, 1fr);

        @include desktop {
            grid-gap: 20px 20px;
        }
    }
}
