/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Menu {
    
}
.Menu-Item{
    height: auto;
}
.Menu-MainCategories{
    width: auto;
}
@include desktop {
    .Menu-SubMenu > .Menu-ItemList{
        columns: 5;
    }
}


@include mobile{
    .Menu-ItemCaption{
        font-size: 16px;
        color: $default-primary-base-color;
        font-weight: 700;
        &:hover{
            color: $default-primary-base-color;
        }
    }
    .Menu-ExpandedState{
        --plus-minus-icon-size: 21px;
        .AddIcon{
            fill: $default-primary-base-color;
        }
    }
    .Menu-ItemList li{
        border-top: 1px solid $grey-4;
        border-bottom: none;
    }
    .Menu-SubMenu_isVisible{
        background: none;
    }
    .Menu-ItemList_type_subcategory{
        padding-bottom: 16px;
    }
    .Menu-SubMenu .Menu-ItemCaption{
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.Menu-SubMenu{
    .Menu-ItemList{
        > div{
            flex: 0 0 100%;
        }
    }
}