/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
  --header-logo-width: 90px;
  --header-logo-height: 29px;

  --header-height: 60px;
  --header-nav-height: 50px;
  --header-total-height: 77px;

  @include desktop {
    --header-top-menu-height: 32px;
    --header-height: calc(
      var(--header-nav-height) + var(--header-top-menu-height) +
        var(--menu-total-height)
    );
  }
}

@mixin button-invisible {
  margin: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  width: 0;
  height: 0;
}

@mixin button-visible {
  opacity: 1;
  height: 25px;
  width: 25px;
  pointer-events: all;
  overflow: visible;
  cursor: pointer;
}

@mixin visible-on-mobile {
  @include button-visible;

  @include desktop {
    @include button-invisible;
  }
}

@mixin logo-visible {
  opacity: 1;
  max-width: 90px;
  @include mobile {
    max-width: 100%;
  }
}

.Header {
  align-items: center;
  background: var(--color-white);
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding-block-start: env(safe-area-inset-top, 0);
  width: calc(100% - 16px);
  position: fixed;
  z-index: 99999;
  border-block-end: none;
  inset-block-start: var(--demo-notice-height);

  > * {
    max-width: 100%;
    width: 100%;
  }

  &-Backdrop {
    display: none;

    &_isBackdrop {
      position: fixed;
      display: block;
      top: 0;
      left: 0;
      opacity: 0.6;
      width: 100%;
      height: 100vh;
      background: #000;
    }
  }

  &_menu,
  &_menu_subcategory {
    .Header-Button_type {
      &_close {
        @include desktop {
          @include button-visible;
        }
      }

      &_menu {
        @include desktop {
          @include button-invisible;
        }
      }
    }
  }

  &_name {
    &_menu,
    &_search,
    &_menu_subcategory {
      border-block-end: none;
    }

    &_category {
      position: fixed;

      @include mobile {
        border-block-end: none;
      }
    }
  }

  &-Button {
    @include button-invisible;

    &_isVisible {
      @include mobile {
        @include button-visible;
      }
    }

    &_isDisabled {
      pointer-events: none;
      opacity: 0.1;
    }

    &_type {
      &_menu,
      &_minicart {
        @include button-visible;

        @include desktop {
          height: 24px;
          margin-inline-start: 35px;
        }
      }

      &_account {
        @include button-visible;
      }

      &_home {
        @include visible-on-mobile;
      }

      &_minicart {
        @include desktop {
          margin-inline-start: 35px;
        }
      }

      &_back {
        .ChevronIcon {
          width: 20px;
          height: 20px;
        }
      }

      &_close {
        .CloseIcon {
          width: 27px;
          height: 27px;
        }
      }

      &_share {
        @include button-visible;
      }

      &_compare {
        @include button-visible;
      }
    }

    &[aria-hidden="true"] {
      display: none;
    }
  }

  &-MinicartButtonWrapper {
    cursor: pointer;
  }

  &-CompareButtonWrapper {
    margin-inline-start: 35px;
    height: 24px;
  }

  &-LogoWrapper {
    inset-block-end: 0;
    height: 29px;
    inset-inline-start: 0;
    max-width: 0;
    opacity: 0;
    overflow: hidden;
    inset-inline-end: 0;
    inset-block-start: 0;

    @include mobile {
      margin: auto;
    }

    @include desktop {
      @include logo-visible;
    }

    &_isVisible {
      @include logo-visible;
    }
    .Image-Image {
      @include mobile {
        margin-left: 21px;
      }
    }
  }

  &-Minicart {
    &ButtonWrapper {
      height: 100%;
      width: 100%;
    }

    &ItemCount {
      background: $theme-red-color;
      border-radius: 50%;
      color: var(--color-white);
      height: 18px;
      padding: 2px;
      position: absolute;
      width: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 80%;
      font-weight: 700;

      @include mobile {
        inset-block-start: -12px;
        inset-inline-end: -11px;
      }

      @include desktop {
        inset-block-start: -5px;
        inset-inline-end: -11px;
      }
    }
  }

  &-Nav {
    align-items: center;
    display: flex;
    height: 58px;
    padding-inline: 14px;

    @include desktop {
      justify-content: unset;
      padding-inline: 50px;
    }

    @include mobile {
      height: 50px;
    }
  }

  &-MyAccount {
    @include desktop {
      height: 18px;
      margin-left: 15px;
    }
  }

  &-News {
    align-items: center;
    display: flex;

    &Button {
      font-size: 12px;
      margin-inline-start: 5px;
    }

    .ExclamationMarkIcon {
      inset-block-end: 1px;
      height: 16px;
      margin-inline-end: 10px;
      width: 16px;
    }
  }

  &-Title {
    color: var(--header-color);
    margin: auto;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    inset-inline-end: 0;
    text-align: center;
    text-overflow: ellipsis;
    inset-block-start: -1px;
    inset-inline-start: 0;
    inset-block-end: 0;
    transition-duration: 200ms;
    transition-property: opacity;
    white-space: nowrap;
    width: 100%;
    text-transform: inherit;
    line-height: 30px;
    height: 30px;

    &_isVisible {
      @include mobile {
        opacity: 1;
        max-width: calc(100% - 80px);
      }
    }
  }

  &-Welcome {
    display: none;
    font-size: 14px;
    margin-inline-end: 16px;
    text-align: end;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px;
    align-self: center;
    line-height: 16px;

    @include wide-desktop {
      display: block;
      max-width: 120px;
      margin-right: 0 !important;
    }
  }

  &-TopMenu,
  &-Nav {
    display: flex;
    margin: auto;
    max-width: var(--content-wrapper-width);
    align-items: center;
  }

  &-TopMenu {
    justify-content: space-between;
    height: var(--header-top-menu-height);
    padding-inline: 32px;
    padding-block-start: 16px;
  }

  &-Switcher {
    align-items: center;
    display: flex;
  }

  &_isCheckout {
    border-bottom: solid 1px $grey-4;
    @include mobile {
      height: 80px;
    }
    .Header {
      &-Button_type_account {
        display: block;
      }

      &-Button_type_close,
      &-Button_type_back {
        @include desktop {
          display: none;
        }
      }

      &-MyAccount {
        @include desktop {
          margin-inline-start: auto;
          height: 24px;
        }

        @include mobile {
          position: absolute;
          inset-inline-end: 10px;
          width: auto;
        }
      }
    }

    .MyAccountOverlay {
      @include mobile {
        inset-block-start: var(--header-total-height);
        height: calc(100vh - var(--header-total-height));
        position: fixed;
        width: 100vw;
      }
    }
    .Header-LogoWrapper {
      height: 29px;
    }
  }

  &-Wrapper {
    display: flex;
    justify-content: center;
    margin-block-end: 78px;

    @include mobile {
      margin-block-end: 70px;
    }

    &_isPrerendered {
      @include mobile {
        .Header-TopMenu,
        .Menu-MenuWrapper {
          display: none;
        }

        .Header-Nav {
          & > * {
            display: none;
          }

          .Header {
            &-Title,
            &-LogoWrapper,
            &-Button {
              &_isVisible {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  &_name_popup {
    z-index: 400;
  }

  &-MyAccountContainer {
    display: flex;
    margin-inline-start: auto;
  }
}

.Header {
  .promotion-top-bar {
    background: $default-primary-base-color;
    color: $white;
    text-align: center;
    padding: 8px 0;
    p {
      color: $white;
      margin: 0;
      padding: 0;
      line-height: 12px;
      font-size: 12px;
      font-weight: 700;
    }
  }
}

@include mobile {
  .Header-Nav .Header-MinicartItemCount {
    top: 12px;
  }
  [dir="ltr"] .Header-AccountBtn {
    margin: 0 15px;
  }
}

.Header-MyAccountLink {
  display: flex;
  button{
    cursor: pointer;
  }
}
.mobile-hide {
  @include mobile {
    display: none !important;
  }
}

.HeritageCollection {
  margin-bottom: 50px;
  @include mobile {
    margin-bottom: 35px;
    text-align: center;
    .pagebuilder-column {
      flex-basis: 96%;
      max-width: 96%;
      margin-left: auto;
      margin-right: auto;
      padding-left: 0 !important;
    }
  }
  .pagebuilder-column {
    img {
      width: 100%;
    }
  }
  &-Heading {
    font-size: 50px;
    line-height: 54px;
    color: $default-primary-base-color;
    @include mobile {
      font-size: 27px;
      line-height: 27px;
    }
  }
  [data-content-type="buttons"] {
    width: 100%;
  }
}
.CmsPage {
  color: $default-primary-base-color;
  .CollectionBanners {
    margin-bottom: 50px;
    @include mobile {
      margin-bottom: 35px;
    }
    .pagebuilder-button-primary {
      background: transparent;
      color: $default-primary-base-color;
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
      border: none;
      padding: 0;
      margin: 0;
      &:not([disabled]):hover {
        background: transparent;
        color: $default-primary-base-color;
        padding: 0;
        margin: 0;
      }
      @include mobile {
        margin-bottom: 45px;
        font-weight: 500;
        font-size: 17px;
      }
    }
    .pagebuilder-button-secondary {
      margin: 0;
    }
  }
  .HomeCollection {
    margin-bottom: 50px;
    @include mobile {
      margin-bottom: 35px;
    }
    figure {
      margin-bottom: 50px;
      @include mobile {
        margin-bottom: 10px;
      }
      img {
        width: 100%;
      }
    }
    h2 {
      margin: 0;
      padding: 0;
      font-size: 38px;
      line-height: 50px;
      font-weight: 700;
      color: $default-primary-base-color;
      margin-bottom: 5px;
      @include mobile {
        font-size: 27px;
      }
    }
    p {
      font-size: 18px;
      line-height: 23px;
      @include mobile {
        display: none;
      }
    }
    .ButtonWrapper {
      margin-top: 20px;
      @include mobile {
        margin-top: 5px;
      }
    }
    @include mobile {
      [data-content-type="button-item"] {
        width: 70%;
      }
    }
    &.SingleBanner {
      margin-bottom: 50px;
      @include mobile {
        margin-bottom: 35px;
      }
      figure {
        margin-bottom: 0;
      }
    }
  }
  .TrendingStyle {
    margin-bottom: 50px;
    @include mobile {
      margin-bottom: 35px;
    }
    h2 {
      margin: 0;
      padding: 0;
      font-size: 26px;
      line-height: 31px;
      font-weight: 700;
      color: $default-primary-base-color;
      margin-bottom: 30px;
      text-align: center;
      @include mobile {
        font-size: 20px;
        margin-bottom: 5px;
      }
    }
  }
  .HeroBanner {
    margin-bottom: 50px;
    @include mobile {
      margin-bottom: 35px;
    }
    .SlickSlider{
      padding: 0;
      .slick-prev{
        left: 20px;
      }
      .slick-next{
        right: 20px;
      }
      .slick-next,
      .slick-prev{
        z-index: 999;
        background: url(images/arrow.png);
        @include mobile {
          height: 32px;
          width: 19px;
          top: 50%;
        }
      }
      .slick-dots{
        margin-top: 10px;
        justify-content: center;
        li{
          flex-grow: inherit;
          margin: 0 8px;
          button{
            width: 12px;
            height: 12px;
            border-radius: 50%;
          }
          &.slick-active{
            button{
              background: $theme-red-color;
            }
          }
        }
      }
    }
    .pagebuilder-overlay {
      transform: translate(20%, 12%);
      @include mobile {
        transform: translate(0, 196px);
        text-align: center;
      }
      h2 {
        color: $white;
        font-size: 50px;
        line-height: 50px;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        @include mobile {
          font-size: 27px;
          line-height: 27px;
        }
      }
      p {
        color: $white;
        font-size: 16px;
        line-height: 28px;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        @include mobile {
          display: none;
        }
      }
      .pagebuilder-slide-button {
        margin: 0;
        background: $white;
        width: 190px;
        height: 45px;
        color: $default-primary-base-color;
        border: none;
        opacity: 1 !important;
        visibility: visible !important;
        @include mobile {
          text-transform: capitalize;
        }
      }
    }
    .Slider-Arrow {
      width: 60px;
      height: 50px;
    }
    .Slider-Arrow_isPrev {
      left: 0;
      svg {
        width: 60px;
        height: 50px;
      }
    }
    .Slider-Arrow_isNext {
      right: 0;
      svg {
        width: 60px;
        height: 50px;
      }
    }
    .ChevronIcon {
      fill: $white;
    }

    .Slider-Crumbs {
      margin-top: 10px;
      margin-bottom: 0;
    }
  }
  .ShopInstagram {
    margin-bottom: 50px;
    @include mobile {
      margin-bottom: 45px;
    }
    h2 {
      margin: 0;
      padding: 0;
      font-size: 38px;
      line-height: 50px;
      color: $default-primary-base-color;
      margin-bottom: 10px;
      @include mobile {
        font-size: 27px;
        line-height: 27px;
        margin-bottom: 10px;
      }
    }
    p {
      color: $default-primary-base-color;
      margin-bottom: 30px;
      font-size: 18px;
      line-height: 23px;
      @include mobile {
        margin-bottom: 25px;
      }
    }
    figure {
      padding: 0 5px;
      img {
        width: 100%;
      }
    }
  }

  .MobileView,
  .WebView {
    display: none !important;
  }
  .WebView {
    display: flex !important;
    @include mobile {
      display: none !important;
    }
  }
  .MobileView {
    @include mobile {
      display: flex !important;
    }
  }

  .OneThirdSlider {
    margin:  0 7px;
    .SlickSlider{
      padding: 0;
      .slick-arrow,
      .slick-dots{
        display: none !important;
      }
      .slick-list {
        padding-left: 0px !important;
      }
      .slick-slide{
        padding: 0 15px 0 0;
        padding-bottom: 50px;
        > div{
          overflow: visible;
        }
      }
      .slick-track{
        > div{
          overflow: visible;
        }
      }
    }
    .pagebuilder-overlay{
      position: absolute;
      bottom: -40px;
      display: block;
      padding: 0;
      min-height: auto !important;
      .pagebuilder-button-primary{
        margin-bottom: 0;
      }
    }
    [data-content-type='slide']{
      overflow: visible;
    }
    .pagebuilder-slide-wrapper{
      min-height: 304px;
      @media screen and (min-width: 400px) {
        min-height: 370px;
      }
      @media screen and (min-width: 500px) {
        min-height: 460px;
      }
      @media screen and (min-width: 600px) {
        min-height: 570px;
      }
    }
  }
}

.Influencer-Hero-Wrapper {
  margin-block-end: 60px;
  @include mobile {
    margin-block-end: 40px;
  }
  img {
    width: 100%;
  }
  .Influencer-Hero-Content {
    position: absolute;
    z-index: 99;
    left: 75px;
    bottom: 75px;
    width: 430px;
    @include mobile {
      position: inherit;
      width: 100%;
      left: inherit;
      bottom: inherit;
      padding: 20px;
    }
    h4 {
      font-size: 42px;
      line-height: 42px;
      font-weight: 700;
      color: $white;
      margin-bottom: 15px;
      @include mobile {
        color: $default-primary-base-color;
        font-size: 27px;
        line-height: 27px;
        margin-bottom: 10px;
      }
    }
    p {
      font-size: 16px;
      line-height: 20px;
      margin: 0;
      color: $white;
      @include mobile {
        color: $default-primary-base-color;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

.Influencer-Card-Wrapper {
  margin-block-end: 60px;
  @include mobile {
    margin-block-end: 0;
  }
  .pagebuilder-column {
    padding: 0 13px;
    @include mobile {
      margin-bottom: 50px;
    }
    img {
      width: 100%;
    }
    h2 {
      font-size: 26px;
      line-height: 50px;
      margin: 0;
      @include mobile {
        text-align: left;
      }
    }
    p {
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 15px;
    }
  }
}

.Influencer-Detail-Hero-Banner {
  margin-block-end: 60px;
  img {
    width: 100%;
  }
}
.Influencer-Detail-Products {
  h2 {
    margin: 0;
    padding: 0;
    font-size: 26px;
    line-height: 31px;
    font-weight: 700;
    margin-bottom: 30px;
    text-align: center;
    @include mobile {
      font-size: 22px;
      margin-bottom: 5px;
    }
  }
}

.About-Us-First-Row {
  background: #0b1f3f;
  margin-block-end: 60px;
  @include mobile{
    padding-bottom: 20px;
    margin-block-end: 30px;
    
  }
  img {
    width: 100%;
  }
  h3 {
    font-size: 36px;
    line-height: 47px;
    color: $white;
    margin-block-start: 0;
    margin-block-end: 40px;
    @include mobile{
      margin-block-end: 00px;

    }
  }
  p {
    color: $white;
    font-size: 16px;
    line-height: 24px;
    @include mobile{
      font-size: 14px;
    }
    a{
      color: $white;

    }
  }
  .pagebuilder-column{
    @include mobile{
      padding-left:15px !important;
      padding-right:15px !important;
    }
  }
}

.About-Us-Second-Row {
  margin-block-end: 60px;
  @include mobile{
   margin-bottom: 20px;
  }
  p {
    font-size: 18px;
    line-height: 24px;
    margin-block-end: 20px;
    @include mobile{
      font-size: 14px;
    }
  }
  .pagebuilder-column{
    @include mobile{
      padding-left:15px !important;
      padding-right:15px !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}

.About-Us-Third-Row {
  img {
    width: 100%;
  }
  h3 {
    font-size: 36px;
    line-height: 47px;
    margin-block-start: 0;
    margin-block-end: 40px;
  }
  p {
    font-size: 16px;
    line-height: 20px;
    @include mobile{
      font-size: 14px;
    }
  }
  .pagebuilder-column{
    @include mobile{
      padding-left:15px !important;
      padding-right:15px !important;
      padding-top: 20px !important;
      padding-bottom: 0 !important;
    }
    h2{
      @include mobile{
        text-align: left;
      }
    }
  }
}

.privacy-policy-left-nav {
  line-height: 40px;
  a {
    text-decoration: none !important;
    span {
      font-size: 16px;
    }
    &:hover {
      text-decoration: none !important;
      span {
        text-transform: uppercase;
        font-weight: 700;
      }
    }
  }
}

.bullets {
  ul {
    padding-left: 25px;
    li {
      list-style-type: disc;
      list-style-position: outside;
    }
  }
}

.ContactUs-Heading {
  h1 {
    font-size: 36px;
    line-height: 43px;
    margin-block-end: 8px;
  }
  p {
    font-size: 16px;
    line-height: 20px;
    margin-block-end: 30px;
  }
}
.contact-details {
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  li {
    display: flex;
    flex-wrap: wrap;
    margin-block-end: 20px;
    align-items: center;
    h3,
    p {
      margin: 0;
      padding: 0;
    }
    span {
      margin-inline-end: 18px;
    }
    a {
      display: flex;
      align-items: center;
      span{
          img{
              display: block;
          }
      }
    }
  }
}

.collection-hero-banner {
  margin-bottom: 50px;
  img {
    width: 100%;
    margin-bottom: 40px;
  }
  > h2 {
    margin: 0;
    padding: 0;
    font-size: 38px;
    line-height: 50px;
    font-weight: 700;
    color: $default-primary-base-color;
    @include mobile {
      font-size: 31px;
    }
  }
  p {
    font-size: 18px;
    line-height: 23px;
    margin: 0;
    padding: 0;
    @include mobile {
      display: none;
    }
  }
}

.collection-slider {
  margin-bottom: 50px;
  @include mobile {
    margin-bottom: 35px;
  }
  h2 {
    margin: 0;
    padding: 0;
    font-size: 26px;
    line-height: 31px;
    font-weight: 700;
    color: $default-primary-base-color;
    margin-bottom: 30px;
    text-align: center;
    @include mobile {
      font-size: 22px;
      margin-bottom: 5px;
    }
  }
}

.collection-shop-wrapper {
  margin-bottom: 50px;
  @include mobile {
    margin-bottom: 35px;
  }
}

.Featured-Collections {
  margin-bottom: 50px;
  @include mobile {
    margin-bottom: 35px;
  }
  h5 {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;
  }
  h2 {
    margin: 0;
    padding: 0;
    font-size: 26px;
    line-height: 31px;
    font-weight: 700;
    color: $default-primary-base-color;
    margin-bottom: 30px;
    text-align: center;
    @include mobile {
      font-size: 22px;
      margin-bottom: 5px;
    }
  }
  a.pagebuilder-button-link {
    margin: 5px 0;
    font-size: 18px;
    line-height: 23px;
    font-weight: 700;
  }
}

.The-History {
  margin-bottom: 50px;
  @include mobile {
    margin-bottom: 35px;
  }
  img {
    width: 100%;
  }
  h2 {
    margin: 0;
    padding: 0;
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 12px;
  }
  p {
    line-height: 24px !important;
    margin: 0;
    padding: 0;
    @include mobile {
      text-align: center;
    }
    &:last-child{
      @include mobile {
        margin-bottom: 30px;
      }
    }
  }
  .text {
    padding: 0 30px;
  }
}

.Shop-Grant-Hill {
  margin-bottom: 50px;
  @include mobile {
    margin-bottom: 35px;
  }
  img {
    width: 100%;
  }
  h2 {
    margin: 0;
    padding: 0;
    font-size: 26px;
    line-height: 31px;
    font-weight: 700;
    color: $default-primary-base-color;
    margin-bottom: 30px;
    text-align: center;
    @include mobile {
      font-size: 22px;
      margin-bottom: 5px;
    }
  }
  .pagebuilder-column {
    figure {
      padding: 0 22px;
    }
  }
}

.collection-three-hero-banner {
  margin-bottom: 50px;
  figure {
    display: contents;
  }
  .left-image {
    img {
      width: 100%;
    }
  }
  .Content {
    figure {
      margin-block-end: 20px;
      display: block;
    }
    h2 {
      margin: 0;
      padding: 0;
      font-weight: 700;
      font-size: 72px;
      line-height: 95px;
      @include mobile {
        font-size: 40px;
        line-height: 60px;
      }
    }
    p {
      text-align: center;
      max-width: 40%;
      margin: 15px auto;
      line-height: 20px;
      font-size: 14px;
    }
  }
}

.collection-head {
  margin-bottom: 50px;
  p {
    font-size: 16px;
    line-height: 24px;
  }
}

.collection-three-sections {
  margin-bottom: 50px;
  h2 {
    font-size: 32px;
    line-height: 42px;
    margin-bottom: 20px;
  }
}

.collection-three-product-sections {
  margin-bottom: 50px;
  > h2 {
    margin-bottom: 10px;
  }
  figure {
    display: contents;
  }
  .pagebuilder-column {
    padding: 0 6px;
    @include mobile {
      padding: 0;
    }
    h2 {
      font-size: 26px;
      line-height: 34px;
      margin-bottom: 15px;
    }
    p {
      line-height: 24px;
      font-size: 16px;
      margin-bottom: 20px;
      padding: 0 30px;
    }
  }
}

.collection-three-product-listing {
  h2 {
    font-size: 32px;
    line-height: 42px;
    margin-bottom: 20px;
  }
  .pagebuilder-column {
    padding: 0 15px;
    margin-bottom: 50px;
    img {
      width: 100%;
    }
    figure {
      margin-bottom: 8px;
    }
    .pagebuilder-button-link {
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
    }
  }
}
.HomePage {
  .CmsPage {
    .pagebuilder-button-primary,
    .pagebuilder-button-secondary {
      @include mobile {
        text-transform: capitalize;
      }
    }
    &-Wrapper{
      @include mobile{
        padding-inline: 0px !important;
      }
    }
  }
}

.peforma-title {
  @include desktop {
    text-transform: uppercase;
  }
}


// brand css
.CmsPage{
  .brand-desc{
    text-align: center;
    padding: 50px 0;
    @include mobile{
      padding: 20px 15px;
      text-align: left;
    }
  }
  .row-data{
    margin-bottom: 50px;
    text-align: center;
    @include mobile{
      margin-bottom: 20px;
    }
    .pagebuilder-column{
        p{
          line-height: 2em;
        }
        .right-data{
          padding-top: 50px;
          padding-right: 60px;
          @include mobile{
            padding: 30px 15px;
            padding-bottom: 0px;
            text-align: left !important;
            h2{
              text-align: left !important;
            }
          }
        }
    }
  }
  .short-desc-row{
    padding-top: 30px;
    @include mobile{
      padding-top: 0;
      padding-left: 15px;
      padding-right: 15px;
    }
    p{
      line-height: 2em;
      font-weight: 700;
    }
  }
  p{
    @include mobile{
      line-height: 1.2em;
    }
    
  }
}

  iframe{
    &#launcher {
      @include mobile{
        bottom:65px !important;
      }
       

  }
}