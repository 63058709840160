/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 :root {
    --popup-background: #0003;
    @include mobile {
        --popup-content-padding: 14px;
    }

    --popup-content-background: #{$white};
    --popup-min-width: 100%;
    --popup-content-padding: 12px;

    @include desktop {
        --popup-min-width: 490px;
        --popup-content-padding: 24px;
    }
}

.Popup {
    opacity: 0;
    pointer-events: none;
    z-index: 9999;
    position: fixed;
    background-color: var(--popup-background);
    inset-inline-start: 0;
    inset-block-start: var(--header-total-height);
    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile {
        align-items: center;
    }

    &_isVisible {
        pointer-events: all;
        opacity: 1;
        width: calc(100vw - 16px);
        height: 100vh;
        inset-block-start: 0;
        left: 8px !important;

        @include mobile {
            inset-block-start: var(--header-total-height);
            height: calc(100% - var(--header-total-height));
        }
    }

    &-Header{
        @include mobile {
            display: block;
            text-align: right;
        }
    }

    &-Heading {
        text-decoration: none;
        font-weight: 700;
        margin-block-start: 0;
        font-size: 19px;
        text-align: left;
    }

    &-Content {
        background-color: var(--popup-content-background);
        border-radius: 5px;
        padding: var(--popup-content-padding);
        min-width: var(--popup-min-width);
        max-width: calc(var(--content-wrapper-width) * .8);
        max-height: 90%;
        overflow-y: auto;
        
        @include mobile {
            border-radius: 0;
            max-height: 100%;
        }
    }

    & &-CloseBtn {
        position: absolute;
        inset-block-start: -4px;
        inset-inline-end: -6px;
        z-index: 5;
        width: auto;

        @include mobile {
            position: absolute;
            margin-inline-end: 0;
        }

        .CloseIcon {
            height: 30px;
            width: 30px;
        }

        @include mobile {
            inset-block-start: 0;
        }
    }

    .Loader {
        @include mobile {
            position: fixed;
        }
    }

    .NotificationList {
        width: 100vw;
        inset-block-start: 0;
        inset-inline-start: calc(0px - var(--popup-content-padding));
        position: relative;
    }
}

.scrollDisabled {
    position: fixed;
    margin-block-start: 0;
    width: 100%;
    overflow-y: auto;
}
