/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@mixin map-margin-size {
    margin-left: 15px;
    margin-right: 15px;
}

.StoreFinder {
    color: $default-primary-base-color;
    &-MainContent{
        max-width: 1100px;
        margin: 0 auto;
    }
    &-Wrapper {
        @include mobile {
            display: block;
            padding: 0;
        }
    }

    &-Heading {
        text-align: center;
        max-width: 90%;
        margin: 0 auto;
        margin-bottom: 80px;
        &_Title {
            font-size: 36px;
            margin-bottom: 8px;
            line-height: 43px;
            color: $default-primary-base-color;
            @include mobile {
                display: block;
            }
        }
    }

    &-SubHeading{
        font-size: 16px;
        line-height: 20px;
    }

    &-Map {
        z-index: 0;
        @include map-margin-size;

        .leaflet-container {
            border: 1px solid rgb(219, 219, 219);
            height: 600px;

            @include mobile {
                height: 400px;
            }

            .leaflet-control-zoom-in,
            .leaflet-control-zoom-out,
            .leaflet-popup-content {
                margin: 0; // margin set by 'webpack:///./src/app/style/base/_link.scss'
            }
        }

        .leaflet-popup {
            margin-left: 1.25rem;

            @include mobile {
                margin: 0;
            }

            &-tip-container {
                display: none;
            }

            &-content {
                &-wrapper {
                    box-shadow: none;
                    border-radius: 3px;
                    min-width: 200px;
                    width: fit-content;
                }
            }
        }

        .leaflet-marker-icon {
            border: 0;
            background-color: transparent;
            margin-top: -50px !important;
        }

        .StoreFinder-Store {
            padding: 15px;
        }
    }

    &-SelectList {
        padding: 24px 0 15px;
        margin-bottom: 10px;
        background-color: var(--color-storefinder-white);

        @include mobile {
            padding: 10px 15px 20px;
            margin-bottom: 0;
        }
    }

    &-Select {
        width: calc(50% - 10px);
        display: inline-block;

        @include mobile {
            display: block;
            width: 100%;
        }

        &:last-child {
            margin-left: 20px;

            @include mobile {
                margin-left: 0;
                margin-top: 20px;
            }
        }

        .Select {
            &-Form {
                font-size: 12px;
                line-height: 1;
                padding: 13px 50px 13px 20px;
            }

            &-Elements {
                li {
                    font-size: 12px;
                    padding: 0 50px 0 20px;
                    height: 40px;
                }
            }

            &-Wrapper:hover > ul li {
                @include mobile {
                    max-height: 40px;
                }
            }
        }
        .Field{
            margin-block-start: 0;
        }
        > label{
            font-size: 16px;
            font-weight: 700;
            color: $default-primary-base-color;
            margin-bottom: 5px;
        }
    }

    &-SelectListWrapper{
        border: solid 1px $grey-4;
        padding: 50px;
        @include mobile {
            padding: 20px;
        }
    }

    &-StoreCards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 30px;
        padding: 15px 0;

        @include tablet {
            grid-template-columns: repeat(2, 1fr);
        }

        @include mobile {
            grid-template-columns: 1fr;
            grid-gap: 15px;
            padding: 0 0 5px;
            margin: 20px 15px 0;
        }
    }

    &-Store {
        background-color: var(--color-storefinder-white);
        border: 1px solid var(--input-border-color);
        padding: 20px 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &_isActive {
            border: solid 1px var(--primary-base-color);
        }

        .Button {
            margin-top: 10px;
            justify-content: flex-start;
            display: block;
            text-decoration: none;

            &::after {
                content: '';
                width: 1.5rem;
                height: 1.5rem;
                bottom: 0;
                left: 0;
                position: absolute;
            }

            &:hover {
                color: var(--link-hover);
            }
        }

        a {
            margin-top: 0;
            font-weight: normal;
            display: inline-block;
            font-size: 16px;
            border-bottom: solid 1px $default-primary-base-color;
        }


    }

    &-StoreInfo {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        margin: 0;
        display: block;
        margin-bottom: 10px;

        &_type {
            &_name {
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
                margin-bottom: 8px;
                color: $default-primary-base-color;
            }

            &_number {
                font-weight: normal;
            }

            &_workingdays {
                margin: 10px 0;
            }
        }
    }

    &-Circles {
        display: inline-block;
        line-height: 1;
        margin: 0 5px 0 0;
        height: 15px;
        width: 15px;
        border-radius: 50%;
        background-color: var(--secondary-base-color);

        &_isActive {
            background-color: var(--primary-base-color);
        }
    }

    .ContentWrapper {
        @include mobile {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.leaflet-popup-content {
    .StoreFinder {
        &-Store {
            padding: 10px;
        }

        @include mobile {
            font-size: 12px;

            &-StoreInfo_type {
                &_name {
                    font-size: 14px;
                    margin-bottom: 10px;
                }

                &_number {
                    font-weight: bold;
                }

                &_workingdays {
                    margin: 5px 0;
                }

                &_hours {
                    margin: 0;

                    p {
                        font-size: 12px;
                        margin: 5px 0;
                    }
                }
            }
        }
    }
}
