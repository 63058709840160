/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --blog-categories-border: 1px solid lightgray;
    --blog-category-chewron-border: 1px solid gray;
    --blog-category-link-font-size: 14px;
    --blog-category-chewron-size: 10px;
}

.BlogCategories {
    &-Category {
        display: block;
        border-top: none;
        text-align: left;
        margin: 10px 0;
    
        h3 {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 21px;
            margin: 0;
        }
        

        &:last-child {
            border-bottom: none;
            margin-bottom: 20px;
        }

        &::after {
          display: none;
        }

        .BlogSubCategory {
            margin-top: 1.6rem;
            margin-left: 2rem;
        }

        .BlogCategoryTitle {
            color: var(--link-color);

            &:hover {
                text-decoration: underline;
                color: var(--link-hover);
            }

            span {
                color: var(--color-black);
            }
        }
    }

    &-Title {
        text-align: left;
        text-transform: uppercase;
        margin-top: 0;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        margin-block-start: 50px;
        
        + div {
            margin-top: 20px;
        }
    }
}
