/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --search-bar-max-width: 450px;
    --search-bar-width: 270px;
    --search-clear-background: var(--secondary-base-color);
    --search-field-background: var(--secondary-base-color);
    --search-field-border-color: var(--primary-divider-color);
    --search-field-color: var(--secondary-base-color);
    --search-field-visible-background: #fff;
    --search-input-desktop-border-color: #000;
}

.SearchField {
    display: none;

    @include desktop {
        display: block;
        width: 70px;
        max-width: inherit;
        margin-left: auto;
    }

    &_isVisible {
        display: block;

        @include mobile {
            margin-block-start: 16px;
        }
    }

    &_isActive {
        .SearchOverlay {
            &-Results {
                display: block;
            }
        }
    }

    &-CloseIcon,
    &-SearchIcon {
        position: absolute;
        height: 24px;
        inset-inline-end: 15px;
        inset-block-start: calc(50% - 12px);
        width: 24px;
        cursor: pointer;
        @include mobile {
            display: none;
        }
    }

    &-Input {
        width: 100%;
    }
    
}

.SearchField {
    .search-field-wrapper{
        position: fixed;
        left: 8px;
        width: calc(100% - 16px);
        margin-top: 15px;
        background: $white;
        z-index: 99;
        display: none;
        padding-bottom: 15px;
        @include mobile {
            margin-top: 0;
            padding-bottom: 0;
            position: inherit;
            left: 0;
            width: 100%;
        }
        .search-field-container{
            left: 50%;
            transform: translate(-50%, 0);
            width: 1300px;           
            @media(max-width:1300px){
                width: 100%;
            }
            @include mobile{
                width: 100%;
            }
            > input{
                width: 100%;
                border: none;
                border-bottom: solid 1px $grey-4;
                font-size: 26px;
                color: $default-primary-base-color;
                padding: 7px;
                @include mobile{
                    border-bottom: none;
                }
                &::placeholder{
                    color: $default-primary-base-color;
                }                
               
            }
            .SearchField-CloseIcon{
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(-50%, -50%);
            }
        }
        .SearchField-SearchIcon,
        .SearchField-CloseIcon{
            display: block;
        }
    }
    &.SearchField_isActive{
        .search-field-wrapper{
            display: block;
        }
    }
}

@include mobile{
    .SearchField-SearchInnerWrapper{
        > .SearchField-SearchIcon{
            display: none;
        }
    }
}


