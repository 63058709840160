/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --blog-post-card-title-weight: 700;
    --blog-post-card-title-size: 16px;
    --blog-post-card-description-height: 100px;
    --blog-post-card-border: 1px solid lightgray;
}

.BlogPostCard {
    font-size: 12px;
    text-align: left;
    width: 50%;
    max-width: 50%;
    padding: 15px;

    @include mobile {
        width: 100%;
        max-width: 100%;
        padding: 10px;
    }

    &_isLoading {
        padding: 80% 0 0;
        animation: var(--placeholder-animation);
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        box-shadow: var(--attraction-shadow);
    }

    &-Details {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 15px;
        background: rgb(0,0,0);
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
        &_isRelated {
            bottom: auto;
            margin-left: 0;
        }

        a:hover, a:focus {
            text-decoration: none;
        }
    }

    &-ContentWrapper {
        &_isRelated {
            height: 100%;
        }
    }

    &_isCompactOnMobile {
        &-Title {
            @include mobile {
                padding-top: 0;
            }
        }

        &-DescriptionWrapper {
            @include mobile {
                display: none;
            }
        }

        &-Date {
            @include mobile {
                position: absolute;
                bottom: 0;
                display: block;
            }
        }

        &-ContentWrapper {
            @include mobile {
                display: flex;
                width: 100%;
            }
        }

        &-Details {
            @include mobile {
                display: block;
                flex-basis: 60%;
                padding-left: 10px;
            }
        }
    }

    &-Title {
        margin-top: auto;
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 26px;
        color: #FFFFFF;
        margin-bottom: 10px;

        &_isRelated {
            font-size: 1.3rem;
            color: var(--color-black);

            @include desktop {
                color: var(--color-white);
                font-size: 3.5rem;
            }
        }
    }

    &-DescriptionWrapper {
        overflow: hidden;
        max-height: var(--blog-post-card-description-height);
        text-overflow: ellipsis;
    }

    &-Date {
        color: $white;
        font-size: 12px;
        line-height: 18px;
        display: flex;
        align-items: center;
        margin-block-end: 10px;
        svg{
            margin-right: 10px;
        }
    }

    &-Link{
        a{
            color: $white;
            font-size: 14px;
            line-height: 16px;
        }
    }

    &-Image {
        height: 100%;
        padding-bottom: 0;
        max-height: 240px;

        > img {
            max-height: 240px;
            position: relative;
            object-fit: cover;
            object-position: top;
        }
    }

    &-ImageWrapper {
        flex-basis: 40%;
    }
}
