/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 .NoMatch {
    opacity: 0;
    animation: appear 100ms 300ms forwards;

    display: block;
    justify-content: inherit;
    align-items: inherit;
    margin-bottom: 50px;
    @include mobile {
        min-height: inherit;
        margin-block-end: 0;
        margin-block-start: 0;
        margin-bottom: 30px;
    }

    h1 {
        font-size: 36px;
        margin-top: 50px;
        font-weight: 700;
        color: var(--primary-base-color);
        margin-block-end: 15px;

        @include mobile {
            font-size: 30px;
            margin-top: 100px;
        }
    }

    p{
        font-size: 16px;
        margin: 0;
    }

    &-Button {
        margin-block-start: 23px;

        @include mobile {
            margin-block-start: 23px;
        }
    }

    &-Content{
        text-align: center;
        margin-bottom: 100px;
        @include mobile {
            margin-bottom: 50px;
        }
    }

    &-Subtitle {
        font-size: 36px;
        font-weight: 700;
        margin: 0;
        line-height: 48px;

        @include mobile {
            font-size: 42px;
            line-height: 64px;

        }
    }


    .ProductLinks{
        .ProductLinks-Wrapper{
            margin-block-start: 70px;
            margin-block-end: 70px;
            max-width: 60%;
            .ProductLinks-Title{
                margin: 0;
                padding: 0;
                margin-block-end: 22px;
                text-align: center;
                font-size: 26px;
                color: $default-primary-base-color;
                font-weight: 700;
            }
            .ProductLinks-List{
                grid-template-columns: inherit;
                grid-gap: inherit;
                display: block;
            }
        }
    }
}
