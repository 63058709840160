/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.HamburgerMenu {
  --side-menu-width: 300px;

  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  width: var(--side-menu-width);
  background-color: var(--color-white);
  height: 100%;
  z-index: 300;
  transition: transform 250ms ease-out;
  overflow-y: auto;
  padding-block-end: 16px;
  transform: translateX(calc(-1 * var(--side-menu-width)));

  [dir="rtl"] & {
    transform: translateX(calc(1 * var(--side-menu-width)));
  }

  &_isSideMenuOpen {
    transform: translateX(0);
    box-shadow: 0 5px 10px rgba(125, 125, 125, 0.5);

    [dir="rtl"] & {
      transform: translateX(0);
    }
  }

  &-CloseBtnWrapper {
    background-color: var(--color-white);
    width: 100%;
    height: 40px;
    position: sticky;
    inset-block-start: 0;
    z-index: 10;
  }

  &-CloseBtn {
    position: absolute;
    inset-block-start: 0;
    inset-inline-end: 0;
    width: 32px;
    height: 32px;
    margin-block-start: 5px;
    margin-inline-end: 5px;

    svg {
      width: 32px;
      height: 32px;
    }
  }

  .MinusIcon,
  .AddIcon {
    pointer-events: none;
  }
};
