/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

$white: #fff;
$black: #0B1F3F;
$theme-red-color: #E31E3C;
$grey-1: #F6F6F6;
$grey-2: #D8D8D8;
$grey-3: #f5f5f5;
$grey-4: #D9D9D9;
$grey-5: #E5E5E5;
$default-primary-base-color: #0B1F3F;
$default-primary-dark-color: darken($default-primary-base-color, 15%);
$default-primary-light-color: lighten($default-primary-base-color, 15%);
$default-secondary-base-color: #eee;
$default-secondary-dark-color: darken($default-secondary-base-color, 15%);
$default-secondary-light-color: lighten($default-secondary-base-color, 15%);
$font-muli: 'Muli', sans-serif;
$font-standard-size: 62.5%;
