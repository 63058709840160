/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ZipcodeServiceabilityCheck {
    margin-bottom: 35px;

    &-Message {
        display: inline-block;
    }

    &-Input {
        display: inline-grid;
        margin-top: 0;

        input {
            width: 222px;
            border-radius: 0;
            @media screen and (max-width: 480px) {
                width: 155px;
            }
        }
    }

    &-Button {
        @include button;
        border-width: 1px !important;
        width: 110px;
        font-size: 16px;
        border-radius: 0 !important;
        margin-inline-start: 5px;

        @include mobile {
            width: 110px;
        }
    }

    &-Title {
        font-size: 14px;
        font-weight: normal;
        margin: 0;
        color: $default-primary-base-color;
        font-weight: 700;
    }

    &-Availability{
        span{
            font-size: 14px;
            line-height: 18px;
            &.success{
                color: #118821;
            }
            &.error{
                color: red;
            }
        }
    }

    &-Wrapper{
        margin-bottom: 12px;
    }
}
