/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 .CmsPage {
    &_isBreadcrumbsHidden {
        margin-block-start: calc(var(--header-total-height) + 12px);

        @include mobile {
            margin-block-start: calc(var(--header-total-height) + 14px);
        }
    }

    &-Content {
        .cms-content {
            @include default-list;
        }

        h2 {
            @include mobile {
                text-align: center;
            }
        }
        h1, h2, h3, h4{
            margin-top: 0;
        }
    }

    &-Wrapper {
        grid-template-columns: auto;
        max-width: var(--content-wrapper-width);
        padding-inline: 32px;
        margin-block-start: calc(var(--header-total-height) + 12px);
        margin-block-end: var(--header-nav-height);
        margin-inline: auto;

        @include mobile {
            padding-inline: 14px;
            margin-block-start: inherit;
        }

        @include desktop {
            margin-block-start: 0;
        }

        &_page_width {
            &_full {
                max-width: 100%;
                padding-inline: 0;
            }
        }
        .CmsPage-Content{
            > [data-content-type='row'][data-appearance='contained']{
                padding: 0 50px;
                max-width: var(--content-wrapper-width);

                @include mobile {
                    padding: 0;
                }
            }
        }
    }

    &-Heading {
        margin-block-end: 18px;

        @include mobile {
            margin-block-end: 21px;
            display: none;
        }
    }

    &-SectionPlaceholder {
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        animation: var(--placeholder-animation);
        margin: 0;
        margin-block-end: 10px;
        padding-block-end: 30%;

        @include mobile {
            padding-block-end: 60%;
        }

        &:first-child {
            padding-block-end: 40%;

            @include mobile {
                padding-block-end: 100%;
            }
        }
    }

    .TextPlaceholder {
        margin-block-end: 22px;
        display: block;

        @include mobile {
            margin-block-end: 25px;
        }
    }

    .Slider-Crumbs {
        position: unset;
        margin-block: 16px 40px;

        @include mobile {
            margin-block: 20px 28px;
        }
    }

    h3 {
        @include mobile {
            font-size: 14px;
        }
    }

    .widget {
        overflow-x: auto;
    }
}


.HomePage{
    .CmsPage{
        .CmsPage-Wrapper .CmsPage-Content > [data-content-type='row'][data-appearance='contained']{
            padding: 0;
        }
    }
}