/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
  --load-more-button-background: #{$white};
  --load-more-button-color: #{$default-primary-base-color};
  --load-more-button-border: 2px solid #{$default-secondary-base-color};
}

.PostsListing {
  .ContentWrapper {
    max-width: 1440px;
    padding: 0 50px;
    margin: auto;
    @include mobile {
      padding: 0 10px;
    }
  }
  @include desktop {
    margin-bottom: 60px;
  }

  &-GridWrapper {
    width: 100%;
  }
  
  &-Wrapper {
    @include mobile {
      padding-top: 5px;
    }
  }

  &-ColumnWrapper {
    display: flex;

    @include mobile {
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  &-Sidebar {
    min-width: 220px;
    max-width: 280px;

    @include mobile {
      order: 3;
      min-width: 100%;
    }
  }

  &-Grid {
    display: flex;
    flex-wrap: wrap;
    @include desktop{
      margin: 0 -15px;
    }
    @include mobile{
      margin: 0 -10px;
    }
  }

  &-Title {
    text-align: left;
    text-transform: uppercase;
    font-style: normal;
    font-weight: bold;
    font-size: 3.5rem;
    line-height: 4rem;
    padding: 0;
    margin-top: 0;
    margin-bottom: 24px;
  }

  &-Search {
    @include mobile {
      display: none;
    }
  }

  &-LoadMoreWrapper {
    padding-top: 40px;
    text-align: center;
    @include mobile {
      order: 2;
      width: 100%;
    }
    flex-basis: 100%;
  }

  & &-LoadMoreButton {
    width: 158px;
    height: 56px;
    margin: auto;
    color: var(--load-more-button-color);
    background-color: var(--load-more-button-background);
    border: none;
    border-radius: 3px;
    @include button;
  }
}
