/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.StoreDetails {
    &-Container {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-gap: 30px;

        @include mobile {
            grid-template-columns: 1fr;
            grid-gap: 5px;
        }
    }


    &-column2{
        @include desktop {
            padding-top: 70px;
        }
    }

    &-Text {
        font-size: 14px;
        font-weight: 500;
        margin: 0;
        display: block;
        margin-bottom: 10px;
        line-height: 1;
        text-align: center;
        flex: 0 0 100%;
        ul.Store-Working{
            text-align: left;
            width: 60%;
            @include mobile {
                width: 100%;
            }
            li{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                font-size: 16px;
                line-height: 30px;
                color: $default-primary-base-color;
                &:before{
                    display: none;
                }
            }
        }
    }

    &-Name {
        font-size: 31px;
        line-height: 43px;
        color: $default-primary-base-color;
        margin-bottom: 30px;
    }

    &-MoreDetailsContainer {
        display: flex;
        flex-wrap: wrap;
        border: solid 1px $grey-4;
        padding-bottom: 10px;
        margin-block-end: 30px;

        div {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            flex: 0 0 100%;
            margin: 6px 0;
            padding: 0 20px;

            > span{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                svg{
                    margin-right: 10px;
                }
            }

            label {
                text-align: left;
            }
        }

        > button{
            margin: 25px 20px 0 20px;
            svg{
                margin-right: 10px;
            }
        }
    }

    &-MoreDetailsContainerHeading{
        font-size: 18px;
        color: $default-primary-base-color;
        font-weight: 700;
        background: $grey-3;
        display: block;
        width: 100%;
        padding: 14px 20px;
        margin-bottom: 10px;
    }

    &-MoreDetails1 {
        grid-column-start: 2;
        grid-column-end: 5;
    }

    &-MoreDetails2 {
        grid-column-start: 2;
        grid-column-end: 5;
    }

    &-MoreDetails3 {
        grid-column-start: 2;
        grid-column-end: 5;
    }

    &-Label {
        font-weight: bold;
    }

    &-WorkingDays {
        padding-bottom: 10px;
    }

    &-Circles {
        display: inline-block;
        line-height: 1;
        margin: 0 5px 0 0;
        height: 15px;
        width: 15px;
        border-radius: 50%;
        background-color: var(--secondary-base-color);

        &_isActive {
            background-color: var(--primary-base-color);
        }
    }
}
