/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.SizechartTabs{
  display: flex;
  justify-content: space-evenly;
  margin-block-start: 15px;
  border-bottom: solid 3px $grey-4;
  margin-bottom: 15px;
  &-Tabs{
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    padding: 15px 0;
    color: #0B1F3F8C;
    &_isActive{
      color: $default-primary-base-color;
      &:before{
        content: "";
        background: $theme-red-color;
        height: 3px;
        left: 0;
        bottom: -3px;
        position: absolute;
        width: 100%;
      }
    }
  }
}

.SizechartCms{
  img{
    width: 339px;
    left: 50%;
    transform: translateX(-50%);
  }
}
