/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --blog-search-field-background: #{$default-secondary-base-color};
    --blog-search-field-border-color: #{$default-secondary-dark-color};
}

.BlogSearchBar {
    &-Search {
        margin-bottom: 30px;
        &::before {
            font-size: 12px;
            position: absolute;
            z-index: 1;
            top: 8px;
            right: 15px;
            content: '';
        }
    }

    &-SearchWrapper {
        flex-grow: 1;
        text-align: left;
    }

    &-SearchField {
        font-size: 16px;
        width: 100%;
        height: 50px;
        max-width: 100%;
        padding: 10px;
        border: 1px solid #0B1F3F

        &:focus {
            border-color: transparent;
            border-radius: 2rem 2rem 0 0;
        }
    }
}
