/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Menu-MenuWrapper{
    width: 100%;
    margin-top: 0 !important;
    padding: 0 70px;
    @include mobile{
        padding: 0 10px;
        .StoreSwitcher, .Menu-CompareLinkWrapper{
            display: none;
        }
    }
    .Menu-SubCategoriesWrapper{
        position: fixed;
        left: 8px;
        width: calc(100% - 16px);
        padding: 30px 0;
        background: $white;
        .Menu-SubCategoriesWrapperInner{
            max-width: var(--content-wrapper-width);
            margin: 0 auto;
            padding: 0 50px;
            .Menu-SubCategories{
                .Menu-ItemList{
                    justify-content: flex-start;
                    display: flex;

                    .Menu-SubItemWrapper{
                        width: 180px;
                        flex: 0 0 180px;
                        margin: 0 15px;
                        > .Menu-Link{
                            font-weight: 700;
                            margin: 0;
                            margin-bottom: 10px;
                            display: block;
                            color: $default-primary-base-color;
                            .Menu-ItemCaption{
                                margin: 0;
                                padding: 0;
                            }
                        }
                        .Menu-SubLevelDesktop{
                            .Menu-ItemList{
                                display: block;
                                .Menu-Link{
                                    font-weight: 400;
                                    margin: 0;
                                    margin-bottom: 5px;
                                    display: inline-block;
                                    position: relative;
                                    color: $default-primary-base-color;
                                    padding-bottom: 5px;
                                    clear: both;
                                    float: left;
                                    &:after {
                                        content: '';
                                        position: absolute;
                                        width: 100%;
                                        transform: scaleX(0);
                                        height: 1px;
                                        bottom: 2px;
                                        left: 0;
                                        background-color: $default-primary-base-color;
                                        transform-origin: bottom right;
                                        transition: transform 0.25s ease-out;
                                    }
                                    &:hover{
                                        &:after {
                                            transform: scaleX(1);
                                            transform-origin: bottom left;
                                        }
                                    }
                                    &.shopall{
                                        font-weight: 700;
                                        &:after{
                                            display: none;
                                        }
                                    }
                                    .Menu-ItemCaption{
                                        padding: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.Menu-ItemList{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li{
        margin: 0;
        padding: 0;
        @include mobile{
            flex: 0 0 100%;
            border-bottom: 1px solid #D9D9D9;
        }
        &:before{
            display:none;
        }
        a{
            font-size: 16px;
            font-weight: 700;
            position: relative;
            display: inline-block;
            padding: 5px 0;
            margin: 0 !important;
            color: $default-primary-base-color;
            &:after {
                content: '';
                position: absolute;
                width: 100%;
                transform: scaleX(0);
                height: 2px;
                bottom: 2px;
                left: 0;
                background-color: $default-primary-base-color;
                transform-origin: bottom right;
                transition: transform 0.25s ease-out;
            }
            &:hover{
                &:after {
                    transform: scaleX(1);
                    transform-origin: bottom left;
                }
            }
            @include mobile{
                display: inline-block;
                padding: 0;
            }
            .Menu-ItemCaption_type_main{
                margin: 0;
                text-transform: capitalize;
                color: $default-primary-base-color;
            }
        }
    }

}

@include mobile{
    .Menu-ItemCaption{
        margin: 0 !important;
    }
    .Menu-ItemCaption_type_main{
        margin: 0;
        text-transform: capitalize !important;
    }
    .Menu-ItemCaption{
        font-size: 16px;
        color: $default-primary-base-color;
    }
}

.Menu-ItemList li.Menu-Item a[href*="sale"] figcaption {
    color: red;
    }
    .Menu-ItemList li.Menu-Item a[href*="sale"]:after {
        background-color: red;
    }
.CmsPage .HeroBanner .pagebuilder-slide-wrapper {  background-size: 100% !important;
}