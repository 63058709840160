/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --blog-recent-posts-border: 1px solid lightgray;
}

.BlogRecentPosts {
    &-PostCard {
        display: flex;
        padding: 12px 0;
        flex-wrap: wrap;
        align-items: center;
        .BlogRecentPosts-Wrapper{
            padding-left: 15px;
        }
    }

    &-PostTitle {
        font-weight: 500;
        margin-top: 0;
    }

    &-PostTitlePlaceholder {
        &::after {
            content: 'aaaaaaaaaaa';
            word-break: break-all;
        }
    }

    &-Title {
        margin: 0;
        padding: 0;
        text-align: left;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 19px;
    }

    &-ImageWrapper {
        flex-basis: 40%;
    }

    &-Wrapper {
        display: block;
        flex-basis: 60%;
        .BlogRecentPosts-PostTitle{
            font-size: 18px;
            line-height: 21px;
            font-weight: 700;
            display: block;
        }
    }

    &-Date {
        position: absolute;
        bottom: 0;
    }

    &-DatePlaceholder {
        &::after {
            content: 'aaaa/aa/aa';
        }
    }
}
