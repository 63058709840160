/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.QuickViewPopup {

    .Popup-Content{
        padding: 10px;
        min-width: 1100px;
        max-width: 1100px;
        .Popup-Header{
            height: 35px;
            .Popup-CloseBtn{
                svg{
                    font-size: 20px;
                }
            }
            h3{
                display: none;
            }
        }
    }

    &-ProductDetails{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .ProductGallery{
            width: 53%;
        }
        .ProductActions{
            width: 40%;
        }
        .ProductName-Wrapper{
            padding-block-end: 12px;
            margin-block-end: 15px;
            border-bottom: solid 2px $default-primary-base-color;
            display: flex;
            flex-wrap: wrap;
            align-items: baseline;
            justify-content: space-between;
            @include mobile {
                padding-block-end: 9px;
                margin-block-end: 18px;
            }
            .ProductActions-Title{
                font-size: 26px;
                font-weight: 700;
                line-height: 31px;
                color: $default-primary-base-color;
                margin: 0;
                @include mobile {
                    width: 100%;
                    margin-bottom: 5px;;
                }
            }
            .ProductActions-Section_type_sku{
                align-items: flex-start;
            }
        }
        .ProductActions-Schema{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            color: $default-primary-base-color;
            @include mobile {
                display: block;
            }
            .offer_label{
                font-size: 14px;
                span, strong{
                    font-weight: 700;
                }
            }
            .ProductPrice{
                min-height: auto;
                font-size: inherit;
                line-height: inherit;
                margin-bottom: 5px;
                del{
                    font-size: 16px;
                    font-weight: 700;
                    color: $default-primary-base-color;
                    opacity: 1;
                }
                &-PriceValue{
                    font-size: 16px;
                }
            }
        }
        .ProductActions{
            .ProductConfigurableAttributes-Title{
                margin: 0px;
                margin-bottom: 15px;
                font-size: 14px;
                color: $default-primary-base-color;
            }
            .ProductConfigurableAttributes-SwatchList{
                padding-top: 0;
                padding-left: 4px;
            }
            .ProductAttributeValue-Color,
            .ProductAttributeValue-Image-Overlay{
                width: 34px;
                min-height: 34px;
            }
            .ProductAttributeValue-Color::before,
            .ProductAttributeValue-Image-Overlay::before{
                width: 42px;
                height: 42px;
                top: -4px;
                left: -4px;
            }
            .ProductAttributeValue-Color::after,
            .ProductAttributeValue-Image-Overlay::after{
                top: 12px;
                left: 12px;
                display: none;
            }
            .ProductConfigurableAttributes-SwatchList,
            .ProductConfigurableAttributes-DropDownList{
                padding-bottom: 20px;
                margin-bottom: 20px;
                border-bottom: solid 1px $grey-4;
                justify-content: flex-start;
                @include mobile {
                    margin-bottom: 24px;
                }
            }
            .ProductConfigurableAttributes > div:last-child .ProductConfigurableAttributes-SwatchList,
            .ProductConfigurableAttributes > div:last-child .ProductConfigurableAttributes-DropDownList{
                border-bottom: none;
                padding-bottom: 0px;
                margin-bottom: 18px;
            }
            .ProductAttributeValue-String{
                width: 34px;
                height: 34px;
                line-height: 34px;
                padding:0;
                border-radius: 500%;
                font-size: 12px;
                margin-right: 22px;
                margin-bottom: 12px;
                font-weight: 700;
                color: $default-primary-base-color;
            }
            .ProductAttributeValue-String_isSelected{
                border-color: $theme-red-color;
            }
        }
        .ProductActions-AddToCartWrapper{
            margin-block-start: 0;
            margin-block-end: 25px;
        }
        .ProductActions-Qty.Field{
            margin-inline-end: 10px;
            border: solid 1px $default-primary-base-color;
            [type='number']{
                font-size: 16px;
                color: $default-primary-base-color;
                width: 28px;
                min-width: 28px;
            }
            [type='number'] ~ button{
                border: none;
                width: 30px;
                height: 46px;
            }
        }
        .ProductActions-AddToCartWrapper .ProductActions-AddToCart{
            margin-inline-end: 10px;   
        }
        .ProductActions-ActionButtons{
            .ProductWishlistButton{
                border: solid 1px $default-primary-base-color;
                height: 48px;
                width: 90px;
                align-items: center;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                margin: 0;
            }
        }
        .ZipcodeServiceabilityCheck,
        .ProductInformation-Description,
        .ProductActions-ActionButtons {
            display: none;
        }
        .ProductGallery-Additional{
            position: absolute;
            top: 260px;
            left: 40px;
            transform: translateX(-50%);
            .CarouselScroll{
                transform:  rotate(90deg);
                --carousel-scroll-gap: 50px !important;
                margin-top: 0;
            }
            .CarouselScrollItem{
                transform: rotate(-90deg);
                width: 57px;
                height: 87px;
                border: none;
                filter: opacity(1);
                &_isActive{
                    border-bottom: solid 3px $default-primary-base-color;
                }
            }
        }
        .CarouselScroll-ContentWrapper{
            width: 580px;
        }
        .ProductGallery{
            margin-left: 0;
            margin-right: 0;
            padding-left: 100px;
            @include mobile {
                padding-left: 0;
            }
        }
    }

    @include tablet {
        padding-block-start: 0;
        margin-block-start: 0;
    }

    @include mobile {
        padding-block-end: 133px;
        margin-inline: 16px;
    }

    &-Wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 24px;
        grid-template-rows: min-content min-content;
        padding-block-start: 24px;

        @include desktop {
            grid-column-gap: 48px;
            grid-template-areas: '. actions';
            padding-block-end: 72px;

            .ProductActions {
                grid-area: actions;
            }
        }

        @include mobile {
            grid-template-columns: 100%;
            padding: 0;
        }

        > .RelatedProducts {
            display: none;

            @include desktop {
                display: block;
            }
        }
    }

    .ProductGallerySlider{
        width: 57%;
        padding-left: 99px;
    }
    .ProductGallerySliderThumb{
        .slick-slider{
            .slick-slide{
                .ProductGalleryThumbnailImage{
                    padding: 0;
                }
            }
        }
        
    }
}

.quickview-btn{
    cursor: pointer;
    background: $white;
    font-size: 16px;
    font-weight: 700;
    color: $default-primary-base-color;
    padding: 15px;
    position: absolute;
    z-index: 99;
    width: 200px;
    bottom: -100px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transition: all .4s ease-in-out;
        -moz-transition: all .4s ease-in-out;
        -o-transition: all .4s ease-in-out;
        transition: all .4s ease-in-out;
    @include mobile {
        display: none;
    }
}

.ProductCard-FigureReview{
    &:hover{
        .quickview-btn{
            bottom: 20px;
        }
    }
}