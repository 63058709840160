/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.NewsletterSubscription {
    max-width: 280px;
    margin-left: 130px;
    @include mobile {
        max-width: 280px;
        margin-left: 0;
        margin-bottom: 35px;
    }
    .FieldForm-Fields{
        @include mobile {
            margin-bottom: 35px;
        }
    }
    h3{
        margin: 0;
        padding: 0;
        font-size: 14px;
        color: $white;
        font-weight: 700;
        margin-bottom: 10px;
    }
    h6{
        font-size: 14px;
        color: $white;
        font-weight: 400;
        line-height: 18px;
    }
    .FieldForm {
        &-Fieldset {
            &_isLoading {
                opacity: .7;
                pointer-events: none;
            }
        }
    }
    .Form.FieldForm{
        .FieldForm-Fieldset{
            position: relative;
            .Button{
                position: absolute;
                top: 0;
                right: 0;
                background: transparent;
                border: none;
                @include mobile {
                    width: auto;
                }
            }
        }
    }
    .Field{
        margin-top: 18px;
    }
    .footer-social{
        h3{
            margin: 0;
            padding: 0;
            font-size: 14px;
            color: $white;
            font-weight: 700;
            margin-bottom: 10px;
            @include mobile {
                display: none;
            }
        }
        .social-links{
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            li{
                margin-right: 25px;
                margin-bottom: 0;
                &:before{
                    display: none;
                }
                img{
                    color: $white;
                    height: 20px;
                }
            }
        }
    }
    .social-wrapper{
        .CmsBlock-Wrapper{
            width: 100% !important;
        }
    }
}