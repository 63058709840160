/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Footer {
    background: $default-primary-base-color;
    .Footer-Content{
        // max-width: 1200px;
        margin: 0 auto;
        .ContentWrapper{
            padding: 60px 50px 40px;
            margin: 0 auto; 
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            @include mobile {
                flex-direction: column-reverse;
                padding: 35px 18px 0 18px;
            }
            .CmsBlock-Wrapper{
                width: calc(100% - 412px);
                @include mobile {
                    width: 100%;
                }
                > div{
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    flex-wrap: wrap;
                }
            }
            .Footer-ColumnWrapper{
                @include mobile {
                    width: 100%;
                    display: block;
                }
                display: flex;
                width: calc(100% - 412px);
                justify-content: space-between;
                flex-wrap: wrap;
                .ExpandableContent{
                    @include mobile {
                        flex: 0 0 100%;
                        border-top: 1px solid #263856;
                        padding: 19px 0;
                    }
                    &:last-of-type{
                        @include mobile {
                            border-bottom: 1px solid #263856;
                        }
                    }
                    &-Content{
                        @include desktop{
                            max-height: auto;
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
                .Footer-Column-Expandable{
                    .ExpandableContent-Button{
                        padding: 0;
                        .AddIcon, .MinusIcon{
                            fill: $white;
                        }
                    }
                    .ExpandableContent-Heading{
                        margin: 0;
                        padding: 0;
                        font-size: 14px;
                        color: $white;
                        font-weight: 700;
                        margin-bottom: 10px;
                        @include mobile {
                            margin-bottom: 0;
                        }
                    }
                    .ExpandableContent-Content{
                        line-height: 25px;
                        margin-top: 0;
                        &.Footer-Column-ExpandableContentContent_isContentExpanded{
                            @include mobile {
                                margin-top: 15px;
                            }
                        }
                        a{
                            color: $white;
                            display: block;
                            font-size: 12px;
                            font-weight: normal;
                            padding-bottom: 3px;
                            float: left;
                            clear: both;
                            @include mobile{
                                display: inline-block;
                                clear: none;
                                margin-right: 15px;
                            }
                            &:after {
                                content: '';
                                position: absolute;
                                width: 100%;
                                transform: scaleX(0);
                                height: 1px;
                                bottom: 1px;
                                left: 0;
                                background-color: $white;
                                transform-origin: bottom right;
                                transition: transform 0.25s ease-out;
                            }
                            &:hover{
                                &:after {
                                    transform: scaleX(1);
                                    transform-origin: bottom left;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .Footer-CopyrightContentWrapper{
        max-width: var(--content-wrapper-width);
        margin: 0 auto;
        padding-left: 50px;
        padding-right: 50px;
        @include mobile {
            padding: 0 18px;
        }
        .ContentWrapper{
            border-top: solid 1px #263856;
            padding: 27px 0;
            color: #7A8BA0;
            @include mobile {
                border: none;
                padding: 35px 0;
            }
            .copyright{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                > span{
                    margin-right: 15px;
                }
            }
        }
    }
} 